<template>
  <div class="col-12 col-lg-8 c g">
    <div class="card">
      <div class="card-header">
        <h4>
          <i class="fa fa-cogs"></i>
          اعدادات عامة
        </h4>
      </div>
      <div class="card-body">
        <b-form-checkbox
          v-model="accept_all_devices"
          name="check-button"
          class="custom-control-success"
          switch
          inline
        >
          <h5>
            <i class="fa fa-user-check"></i>
            قبول جميع الاجهزة عند تسجيل دخول اولياء الامور
          </h5>
          <span
            class="text-danger"
            v-if="!accept_all_devices || accept_all_devices == 'false'"
          >
            سيتم الآن مراجعة جميع الاجهزة يدوياً اذا اختلف الجهاز الذي تم الدخول
            به اول مرة.
          </span>
        </b-form-checkbox>
      </div>
      <div class="col-12 g text-center">
        <button class="btn btn-success" @click="save()">
          <i class="fa fa-save"></i>
          حفظ الاعدادات
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
export default {
  components: {
    BFormCheckbox,
  },
  data() {
    return {
      accept_all_devices: false,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    if (!checkPer("settings")) {
      this.$router.push("/per");
      return false;
    }
    var g = this;
    $.post(api + "/user/general/general-settings", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.accept_all_devices = r.response.accept_all_devices;
      })
      .catch(function () {
        alert("حدث خطا في الاتصال");
      });
  },
  methods: {
    save() {
      var g = this;
      $.post(api + "/user/general/general-settings-save", {
        jwt: g.user.jwt,
        accept_all_devices: g.accept_all_devices,
      })
        .then(function (r) {
          if (r.status == 100) {
            alert("تم الحفظ");
          } else {
            alert("حدث خطأ");
          }
        })
        .catch(function () {
          alert("حدث خطا في الاتصال");
        });
    },
  },
};
</script>

<style>
</style>